"use client";

import React, { createContext, useContext, useState, useEffect } from "react";
import { Publisher, Category } from "@/types/Publisher";

interface PublisherContextType {
  publishers: Publisher[];
  categories: Category[];
  isLoading: boolean;
  error: Error | null;
  selectedPublisherIds: string[];
  selectPublisher: (publisherId: string) => void;
  deselectPublisher: (publisherId: string) => void;
  togglePublisher: (publisherId: string) => void;
  selectAllPublishers: () => void;
  deselectAllPublishers: () => void;
  toggleCategoryPublishers: (categoryId: string) => void;
  getSelectedCount: () => number;
  getCategorySelectedCount: (categoryId: string) => number;
  isCategoryFullySelected: (categoryId: string) => boolean;
  isCategoryPartiallySelected: (categoryId: string) => boolean;
  isAllPublishersSelected: () => boolean;
}

const PublisherContext = createContext<PublisherContextType | undefined>(
  undefined
);

export function PublisherProvider({ children }: { children: React.ReactNode }) {
  const [publishers, setPublishers] = useState<Publisher[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedPublisherIds, setSelectedPublisherIds] = useState<string[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    async function fetchPublishers() {
      console.log("Starting publisher fetch");
      try {
        const res = await fetch("/api/publishers");
        if (!res.ok) throw new Error("Failed to fetch publishers");

        const data = await res.json();
        console.log("Publishers loaded:", {
          publisherCount: Object.keys(data.publishers).length,
          sampleIds: Object.keys(data.publishers).slice(0, 3),
        });

        // Transform the API response into our internal format
        const transformedCategories = data.categories.map((cat: any) => ({
          id: cat.id,
          name: cat.name,
        }));

        // Transform publishers and associate them with categories
        const transformedPublishers = Object.values(data.publishers).map(
          (pub: any) => {
            const categoryIds = data.categories
              .filter((cat: any) => cat.publishers.includes(pub.id))
              .map((cat: any) => cat.id);

            return {
              id: pub.id,
              name: pub.name,
              domain: pub.domain,
              image: pub.image,
              primaryColor: pub.primaryColor,
              logo: pub.logo,
              categoryIds,
            };
          }
        );

        console.log("Publishers transformed:", {
          count: transformedPublishers.length,
          sampleIds: transformedPublishers.slice(0, 3).map((p) => p.id),
        });

        setPublishers(transformedPublishers);
        setCategories(transformedCategories);
        // Initialize with all publishers selected
        setSelectedPublisherIds(
          transformedPublishers.map((pub: Publisher) => pub.id)
        );
      } catch (err) {
        console.error("Publisher fetch error:", err);
        setError(err instanceof Error ? err : new Error("Unknown error"));
      } finally {
        setIsLoading(false);
      }
    }

    fetchPublishers();
  }, []);

  const selectPublisher = (publisherId: string) => {
    setSelectedPublisherIds((prev) =>
      prev.includes(publisherId) ? prev : [...prev, publisherId]
    );
  };

  const deselectPublisher = (publisherId: string) => {
    setSelectedPublisherIds((prev) => prev.filter((id) => id !== publisherId));
  };

  const togglePublisher = (publisherId: string) => {
    setSelectedPublisherIds((prev) =>
      prev.includes(publisherId)
        ? prev.filter((id) => id !== publisherId)
        : [...prev, publisherId]
    );
  };

  const selectAllPublishers = () => {
    setSelectedPublisherIds(publishers.map((pub) => pub.id));
  };

  const deselectAllPublishers = () => {
    setSelectedPublisherIds([]);
  };

  const toggleCategoryPublishers = (categoryId: string) => {
    const categoryPublishers = publishers.filter((pub) =>
      pub.categoryIds.includes(categoryId)
    );
    const categoryPublisherIds = categoryPublishers.map((pub) => pub.id);

    const allSelected = categoryPublisherIds.every((id) =>
      selectedPublisherIds.includes(id)
    );

    if (allSelected) {
      setSelectedPublisherIds((prev) =>
        prev.filter((id) => !categoryPublisherIds.includes(id))
      );
    } else {
      setSelectedPublisherIds((prev) => {
        const newSelection = new Set([...prev, ...categoryPublisherIds]);
        return Array.from(newSelection);
      });
    }
  };

  const getSelectedCount = () => selectedPublisherIds.length;

  const getCategorySelectedCount = (categoryId: string) => {
    const categoryPublishers = publishers.filter((pub) =>
      pub.categoryIds.includes(categoryId)
    );
    return categoryPublishers.filter((pub) =>
      selectedPublisherIds.includes(pub.id)
    ).length;
  };

  const isCategoryFullySelected = (categoryId: string) => {
    const categoryPublishers = publishers.filter((pub) =>
      pub.categoryIds.includes(categoryId)
    );
    return categoryPublishers.every((pub) =>
      selectedPublisherIds.includes(pub.id)
    );
  };

  const isCategoryPartiallySelected = (categoryId: string) => {
    const categoryPublishers = publishers.filter((pub) =>
      pub.categoryIds.includes(categoryId)
    );
    const selectedCount = categoryPublishers.filter((pub) =>
      selectedPublisherIds.includes(pub.id)
    ).length;
    return selectedCount > 0 && selectedCount < categoryPublishers.length;
  };

  const isAllPublishersSelected = () => {
    return (
      publishers.length === selectedPublisherIds.length &&
      publishers.every((pub) => selectedPublisherIds.includes(pub.id))
    );
  };

  const value: PublisherContextType = {
    publishers,
    categories,
    isLoading,
    error,
    selectedPublisherIds,
    selectPublisher,
    deselectPublisher,
    togglePublisher,
    selectAllPublishers,
    deselectAllPublishers,
    toggleCategoryPublishers,
    getSelectedCount,
    getCategorySelectedCount,
    isCategoryFullySelected,
    isCategoryPartiallySelected,
    isAllPublishersSelected,
  };

  return (
    <PublisherContext.Provider value={value}>
      {children}
    </PublisherContext.Provider>
  );
}

export function usePublishers() {
  const context = useContext(PublisherContext);
  if (context === undefined) {
    throw new Error("usePublishers must be used within a PublisherProvider");
  }
  return context;
}
