import { signOut } from "next-auth/react";

type RequestOptions = {
  params?: Record<string, string>;
  body?: any;
  isStream?: boolean;
};

class ApiClient {
  private async handleAuthError(response: Response) {
    // Clear the session
    await signOut({ redirect: false });

    // Get the redirect URL from the response
    const redirectUrl = response.headers.get("Location");
    if (redirectUrl) {
      window.location.href = redirectUrl;
    } else {
      // Fallback if no redirect URL
      const currentPath = window.location.pathname + window.location.search;
      const signInUrl = new URL("/auth/signin", window.location.origin);
      signInUrl.searchParams.set("callbackUrl", currentPath);
      window.location.href = signInUrl.toString();
    }
  }

  private async handleResponse(response: Response, isStream?: boolean) {
    if (response.status === 401 || response.status === 307) {
      await this.handleAuthError(response);
      return null;
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (isStream) {
      return response;
    }

    return response.json();
  }

  async get(url: string, options?: RequestOptions) {
    const queryParams = options?.params
      ? `?${new URLSearchParams(options.params)}`
      : "";

    const response = await fetch(`${url}${queryParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      // Don't follow redirects automatically
      redirect: "manual",
    });

    return this.handleResponse(response, options?.isStream);
  }

  async post(url: string, options?: RequestOptions) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: options?.body ? JSON.stringify(options.body) : null,
      // Don't follow redirects automatically
      redirect: "manual",
    });

    return this.handleResponse(response);
  }
}

export const apiClient = new ApiClient();
