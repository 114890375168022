import { Attribution } from "@/types/Thread";

export interface PublisherAttribution {
  id: string;
  score: number;
  percentage: number;
}

export function sortAndCalculatePublisherAttributions(
  attribution: Attribution
): PublisherAttribution[] {
  const publisherAttributions: PublisherAttribution[] = [];

  if (attribution?.publisher_credit_dist) {
    Object.entries(attribution.publisher_credit_dist).forEach(
      ([id, score]) => {
        publisherAttributions.push({ id, score, percentage: 0 });
      }
    );
  }

  const totalScore = publisherAttributions.reduce(
    (sum, attr) => sum + attr.score,
    0
  );

  return publisherAttributions
    .map((attr) => ({
      ...attr,
      percentage:
        totalScore > 0 ? Math.round((attr.score / totalScore) * 100) : 0,
    }))
    .sort((a, b) => b.percentage - a.percentage);
}

export interface DocumentAttribution {
  id: string;
  score: number;
  percentage: number;
}

export function sortedDocumentAttributions(
  attribution: Attribution
): DocumentAttribution[] {
  if (!attribution?.document_credit_dist) {
    return [];
  }

  const creditDist = attribution.document_credit_dist;
  const documentAttributions: DocumentAttribution[] = Object.entries(
    creditDist
  ).map(([id, score]) => ({
    id,
    score,
    percentage: 0,
  }));

  return documentAttributions
    .map((attr) => ({
      ...attr,
      percentage: attr.score,
    }))
    .sort((a, b) => b.percentage - a.percentage);
}
