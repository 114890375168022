import { useState } from "react";
import { useChatContext } from "@/context/ChatContext";
import { usePublishers } from "@/context/PublisherContext";
import { ChatRequest } from "@/types/Chat";
import { apiClient } from "@/utils/apiClient";
import { useRouter } from "next/navigation";
import { useToast } from "@/hooks/use-toast";

export function useQuestionSubmit() {
  const { createThread, addTurn } = useChatContext();
  const { isAllPublishersSelected } = usePublishers();
  const [error, setError] = useState<Error | null>(null);
  const router = useRouter();
  const { toast } = useToast();

  const submitQuestion = async (
    question: string,
    threadId?: string,
    selectedPublisherIds?: string[],
    temperature?: number
  ) => {
    console.log("submitQuestion called with:", {
      question,
      threadId,
      selectedPublisherIds,
      temperature,
    });
    setError(null);

    try {
      const body: ChatRequest = {
        thread_id: threadId || "",
        user_prompt: question,
        temperature,
      };

      if (selectedPublisherIds && !isAllPublishersSelected()) {
        body.inclusion_list = selectedPublisherIds;
      }

      console.log("Sending request to /api/chat with body:", body);

      const data = await apiClient.post("/api/chat", { body });

      if (!data) {
        // ApiClient has handled the auth redirect
        toast({
          title: "Session Expired",
          description: "Please sign in again to continue.",
          variant: "destructive",
        });
        return;
      }

      console.log("Received response from /api/chat:", data);

      if (!threadId) {
        // Create new thread with initial turn
        const newThread = {
          threadId: data.thread_id,
          threadTitle: data.thread_title,
          lastInteraction: Date.now(),
          turns: [
            {
              turnId: data.turn_id.toString(),
              userPrompt: question,
            },
          ],
        };
        console.log("Creating new thread:", newThread);
        createThread(newThread);
      } else {
        // Add turn to existing thread
        const newTurn = {
          turnId: data.turn_id.toString(),
          userPrompt: question,
        };
        console.log("Adding new turn:", newTurn);
        addTurn(newTurn);
      }

      return data;
    } catch (err) {
      const error =
        err instanceof Error ? err : new Error("An unknown error occurred");
      console.error("Error submitting question:", error);
      setError(error);

      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });

      throw error;
    }
  };

  return { submitQuestion, error };
}
