import { Button } from "./ui/button";

interface RecommendedQuestionsProps {
  questions: string[];
  onQuestionClick: (question: string) => void;
  className?: string;
}

export function RecommendedQuestions({
  questions = [],
  onQuestionClick,
  className,
}: RecommendedQuestionsProps) {
  if (!questions?.length) return null;

  return (
    <div className={`flex flex-wrap gap-2 justify-center ${className}`}>
      {questions.map((question, index) => (
        <Button
          key={index}
          variant="secondary"
          className="text-xs px-4 h-auto py-1 rounded-full bg-white/90 hover:bg-white/75 text-gray-600 border border-gray-200 hover:border-gray-300 whitespace-normal text-left"
          onClick={() => onQuestionClick(question)}
        >
          {question}
        </Button>
      ))}
    </div>
  );
}
